import React from 'react'
type Props = {
  message?: string
  className?: string
  isLoading?: boolean
}
const Loading: React.FC<Props> = props => {
  const { message, className, isLoading = true } = props

  if (!isLoading) {
    return <React.Fragment>{props.children}</React.Fragment>
  }

  return (
    <div className={`loading ${className ?? ''}`}>
      <i className='fas fa-spinner-third fa-spin' />
      {message && <React.Fragment>{message}&nbsp;</React.Fragment>}
    </div>
  )
}

export default Loading
